import type { Col } from "../ui/components/DataTable";
import bySport from "./bySport";
import isSport from "./isSport";

type ColTemp = Omit<Col, "title"> & {
	title?: string;
};

const gp = isSport("hockey") ? "GP" : "G";

const sportSpecificCols = bySport<{
	[key: string]: ColTemp;
}>({
	baseball: {
		"pos:SP": {
			desc: "Starting Pitcher",
			sortType: "number",
			title: "SP",
		},
		"pos:RP": {
			desc: "Relief Pitcher",
			sortType: "number",
			title: "RP",
		},
		"pos:C": {
			desc: "Catcher",
			sortType: "number",
			title: "C",
		},
		"pos:1B": {
			desc: "First Baseman",
			sortType: "number",
			title: "1B",
		},
		"pos:2B": {
			desc: "Second Baseman",
			sortType: "number",
			title: "2B",
		},
		"pos:3B": {
			desc: "Third Baseman",
			sortType: "number",
			title: "3B",
		},
		"pos:SS": {
			desc: "Shortstop",
			sortType: "number",
			title: "SS",
		},
		"pos:LF": {
			desc: "Left Fielder",
			sortType: "number",
			title: "LF",
		},
		"pos:CF": {
			desc: "Center Fielder",
			sortType: "number",
			title: "CF",
		},
		"pos:RF": {
			desc: "Right Fielder",
			sortType: "number",
			title: "RF",
		},
		"rating:hpw": {
			desc: "Hitting Power",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Hpw",
		},
		"rating:con": {
			desc: "Contact",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Con",
		},
		"rating:eye": {
			desc: "Eye",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Eye",
		},
		"rating:gnd": {
			desc: "Ground Ball Fielding",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Gnd",
		},
		"rating:fly": {
			desc: "Fly Ball Fielding",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Fly",
		},
		"rating:thr": {
			desc: "Throwing",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Thr",
		},
		"rating:cat": {
			desc: "Catcher Defense",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Cat",
		},
		"rating:ppw": {
			desc: "Pitching Power",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Ppw",
		},
		"rating:ctl": {
			desc: "Control",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Ctl",
		},
		"rating:mov": {
			desc: "Movement",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Mov",
		},
		"rating:ovrSP": {
			desc: "Overall Rating (SP)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrSP",
		},
		"rating:ovrRP": {
			desc: "Overall Rating (RP)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrRP",
		},
		"rating:ovrC": {
			desc: "Overall Rating (C)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrC",
		},
		"rating:ovr1B": {
			desc: "Overall Rating (1B)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Ovr1B",
		},
		"rating:ovr2B": {
			desc: "Overall Rating (2B)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Ovr2B",
		},
		"rating:ovr3B": {
			desc: "Overall Rating (3B)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Ovr3B",
		},
		"rating:ovrSS": {
			desc: "Overall Rating (SS)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrSS",
		},
		"rating:ovrLF": {
			desc: "Overall Rating (LF)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrLF",
		},
		"rating:ovrCF": {
			desc: "Overall Rating (CF)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrCF",
		},
		"rating:ovrRF": {
			desc: "Overall Rating (RF)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrRF",
		},
		"rating:ovrDH": {
			desc: "Overall Rating (DH)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrDH",
		},
		"rating:potSP": {
			desc: "Potential Rating (SP)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotSP",
		},
		"rating:potRP": {
			desc: "Potential Rating (RP)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotRP",
		},
		"rating:potC": {
			desc: "Potential Rating (C)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotC",
		},
		"rating:pot1B": {
			desc: "Potential Rating (1B)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pot1B",
		},
		"rating:pot2B": {
			desc: "Potential Rating (2B)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pot2B",
		},
		"rating:pot3B": {
			desc: "Potential Rating (3B)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pot3B",
		},
		"rating:potSS": {
			desc: "Potential Rating (SS)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotSS",
		},
		"rating:potLF": {
			desc: "Potential Rating (LF)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotLF",
		},
		"rating:potCF": {
			desc: "Potential Rating (CF)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotCF",
		},
		"rating:potRF": {
			desc: "Potential Rating (RF)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotRF",
		},
		"rating:potDH": {
			desc: "Potential Rating (DH)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotDH",
		},
		"stat:keyStats": {
			desc: "Key Stats",
			sortSequence: ["desc", "asc"],
			sortType: "string",
			title: "Stats",
		},
		"stat:pa": {
			desc: "Plate Appearances",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PA",
		},
		"stat:r": {
			desc: "Runs",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "R",
		},
		"stat:pts": {
			desc: "Runs",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "R",
		},
		"stat:h": {
			desc: "Hits",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "H",
		},
		"stat:2b": {
			desc: "Doubles",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "2B",
		},
		"stat:3b": {
			desc: "Triples",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "3B",
		},
		"stat:hr": {
			desc: "Home Runs",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "HR",
		},
		"stat:rbi": {
			desc: "Runs Batted In",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "RBI",
		},
		"stat:sb": {
			desc: "Stolen Bases",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SB",
		},
		"stat:cs": {
			desc: "Caught Stealing",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "CS",
		},
		"stat:bb": {
			desc: "Walks",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BB",
		},
		"stat:so": {
			desc: "Strikeouts",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SO",
		},
		"stat:gdp": {
			desc: "Double Plays Grounded Into",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GDP",
		},
		"stat:hbp": {
			desc: "Times Hit by a Pitch",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "HBP",
		},
		"stat:sh": {
			desc: "Sacrifice Hits/Bunts",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SH",
		},
		"stat:sf": {
			desc: "Sacrifice Flies",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SF",
		},
		"stat:ibb": {
			desc: "Intentional Walks",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "IBB",
		},
		"stat:w": {
			desc: "Wins",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "W",
		},
		"stat:l": {
			desc: "Losses",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "L",
		},
		"stat:gpPit": {
			desc: "Games Played",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GP",
		},
		"stat:gsPit": {
			desc: "Games Started",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GS",
		},
		"stat:gf": {
			desc: "Games Finished",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GF",
		},
		"stat:cg": {
			desc: "Complete Games",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "CG",
		},
		"stat:sho": {
			desc: "Shutouts",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SHO",
		},
		"stat:sv": {
			desc: "Saves",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SV",
		},
		"stat:ip": {
			desc: "Innings Pitched",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "IP",
		},
		"stat:outs": {
			desc: "Outs Pitched",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Outs",
		},
		"stat:rPit": {
			desc: "Runs Allowed",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "R",
		},
		"stat:er": {
			desc: "Earned Runs Allowed",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ER",
		},
		"stat:hPit": {
			desc: "Hits Allowed",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "H",
		},
		"stat:2bPit": {
			desc: "Doubles Allowed",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "2B",
		},
		"stat:3bPit": {
			desc: "Triples Allowed",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "3B",
		},
		"stat:hrPit": {
			desc: "Home Runs Allowed",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "HR",
		},
		"stat:bbPit": {
			desc: "Walks",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BB",
		},
		"stat:soPit": {
			desc: "Strikeouts",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SO",
		},
		"stat:ibbPit": {
			desc: "Intentional Walks",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "IBB",
		},
		"stat:hbpPit": {
			desc: "Times Hit by a Pitch",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "HBP",
		},
		"stat:shPit": {
			desc: "Sacrifice Hits/Bunts Allowed",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SH",
		},
		"stat:sfPit": {
			desc: "Sacrifice Flies Allowed",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SF",
		},
		"stat:bk": {
			desc: "Balks",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BK",
		},
		"stat:wp": {
			desc: "Wild Pitches",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "WP",
		},
		"stat:bf": {
			desc: "Batters Faced",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BF",
		},
		"stat:pc": {
			desc: "Pitch Count",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PC",
		},
		"stat:ab": {
			desc: "At Bats",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "AB",
		},
		"stat:ba": {
			desc: "Batting Average",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BA",
		},
		"stat:obp": {
			desc: "On-Base Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OBP",
		},
		"stat:slg": {
			desc: "Slugging Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SLG",
		},
		"stat:ops": {
			desc: "On-Base + Slugging Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OPS",
		},
		"stat:tb": {
			desc: "Total Bases",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TB",
		},
		"stat:winp": {
			desc: "Win-Loss Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "W-L%",
		},
		"stat:era": {
			desc: "Earned Run Average",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ERA",
		},
		"stat:fip": {
			desc: "Fielding Independent Pitching",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FIP",
		},
		"stat:whip": {
			desc: "Walks And Hits Per Inning Pitched",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "WHIP",
		},
		"stat:h9": {
			desc: "Hits Per 9 Innings",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "H9",
		},
		"stat:hr9": {
			desc: "Home Runs Per 9 Innings",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "HR9",
		},
		"stat:bb9": {
			desc: "Walks Per 9 Innings",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BB9",
		},
		"stat:so9": {
			desc: "Strikeouts Per 9 Innings",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SO9",
		},
		"stat:pc9": {
			desc: "Pitches Per 9 Innings",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PC9",
		},
		"stat:sow": {
			desc: "Strikeouts Per Walk",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SO/W",
		},
		"stat:rbat": {
			desc: "Batting Runs",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Rbat",
		},
		"stat:rbr": {
			desc: "Baserunning Runs",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Rbr",
		},
		"stat:rfldTot": {
			desc: "Fielding Runs",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Rfld",
		},
		"stat:rpos": {
			desc: "Positional Adjustment Runs",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Rpos",
		},
		"stat:rpit": {
			desc: "Pitching Runs Saved",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Rpit",
		},
		"stat:raa": {
			desc: "Runs Above Average",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "RAA",
		},
		"stat:waa": {
			desc: "Wins Above Average",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "WAA",
		},
		"stat:rrep": {
			desc: "Runs From Replacement Level",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Rrep",
		},
		"stat:rar": {
			desc: "Runs Above Replacement",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "RAR",
		},
		"stat:war": {
			desc: "Wins Above Replacement",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "WAR",
		},
		"stat:warPerPlayer": {
			desc: "WAR Per Player",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "WAR/Player",
		},
		"stat:gpF": {
			desc: "Games Played",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "G",
		},
		"stat:gsF": {
			desc: "Games Started",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GS",
		},
		"stat:cgF": {
			desc: "Complete Games",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "CG",
		},
		"stat:inn": {
			desc: "Innings Played In Field",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Inn",
		},
		"stat:ch": {
			desc: "Defensive Chances",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Ch",
		},
		"stat:po": {
			desc: "Putouts",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PO",
		},
		"stat:a": {
			desc: "Assists",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "A",
		},
		"stat:e": {
			desc: "Errors",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "E",
		},
		"stat:dp": {
			desc: "Double Plays Turned",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "DP",
		},
		"stat:fldp": {
			desc: "Fielding Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Fld%",
		},
		"stat:rfld": {
			desc: "Fielding Runs",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Rfld",
		},
		"stat:rf9": {
			desc: "Range Factor Per 9 Inn",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "RF/9",
		},
		"stat:rfg": {
			desc: "Range Factor Per Game",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "RF/G",
		},
		"stat:pb": {
			desc: "Passed Balls",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PB",
		},
		"stat:sbF": {
			desc: "Stolen Bases",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SB",
		},
		"stat:csF": {
			desc: "Caught Stealing",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "CS",
		},
		"stat:csp": {
			desc: "Caught Stealing Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "CS%",
		},
		"stat:babip": {
			desc: "Batting Average On Balls In Play",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BAbip",
		},
		"stat:iso": {
			desc: "Isolated Power",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ISO",
		},
	},
	basketball: {
		"rating:fg": {
			desc: "Mid Range",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "2Pt",
		},
		"rating:tp": {
			desc: "Three Pointers",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "3Pt",
		},
		"rating:oiq": {
			desc: "Offensive IQ",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "oIQ",
		},
		"rating:dnk": {
			desc: "Dunks/Layups",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Dnk",
		},
		"rating:drb": {
			desc: "Dribbling",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Drb",
		},
		"rating:ins": {
			desc: "Inside Scoring",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Ins",
		},
		"rating:jmp": {
			desc: "Jumping",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Jmp",
		},
		"rating:ft": {
			desc: "Free Throws",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FT",
		},
		"rating:pss": {
			desc: "Passing",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pss",
		},
		"rating:reb": {
			desc: "Rebounding",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Reb",
		},
		"rating:diq": {
			desc: "Defensive IQ",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "dIQ",
		},
		"stat:2pp": {
			desc: "Two Point Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "2P%",
		},
		"stat:2p": {
			desc: "Two Pointers Made",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "2P",
		},
		"stat:2pa": {
			desc: "Two Pointers Attempted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "2PA",
		},
		"stat:pm": {
			desc: "Plus/Minus",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "+/-",
		},
		"stat:pm100": {
			desc: "Plus/Minus Per 100 Possessions",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "+/-",
		},
		"stat:onOff100": {
			desc: "+/- Per 100 Possessions, On Minus Off",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "On-Off",
		},
		"stat:tpp": {
			desc: "Three Point Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "3P%",
		},
		"stat:tp": {
			desc: "Three Pointers Made",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "3P",
		},
		"stat:tpa": {
			desc: "Three Pointers Attempted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "3PA",
		},
		"stat:tpar": {
			desc: "Three Point Attempt Rate (3PA / FGA)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "3PAr",
		},
		"stat:astp": {
			desc: "Percentage of teammate field goals a player assisted while on the floor",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "AST%",
		},
		"stat:ast": {
			desc: "Assists",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "AST",
		},
		"stat:ba": {
			desc: "Blocks Against",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BA",
		},
		"stat:blk": {
			desc: "Blocks",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BLK",
		},
		"stat:blkp": {
			desc: "Percentage of opponent two-pointers blocked",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BLK%",
		},
		"stat:drb": {
			desc: "Defensive Rebounds",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "DRB",
		},
		"stat:drbp": {
			desc: "Percentage of available defensive rebounds grabbed",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "DRB%",
		},
		"stat:drtg": {
			desc: "Defensive Rating (points allowed per 100 possessions)",
			sortSequence: ["asc", "desc"],
			sortType: "number",
			title: "DRtg",
		},
		"stat:dws": {
			desc: "Defensive Win Shares",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "DWS",
		},
		"stat:ewa": {
			desc: "Estimated Wins Added",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "EWA",
		},
		"stat:efg": {
			desc: "Effective Field Goal Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "eFG%",
		},
		"stat:fgp": {
			desc: "Field Goal Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FG%",
		},
		"stat:fg": {
			desc: "Field Goals Made",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FG",
		},
		"stat:fga": {
			desc: "Field Goals Attempted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FGA",
		},
		"stat:ftp": {
			desc: "Free Throw Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FT%",
		},
		"stat:ft": {
			desc: "Free Throws Made",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FT",
		},
		"stat:fta": {
			desc: "Free Throws Attempted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FTA",
		},
		"stat:ftpFga": {
			desc: "Free Throws per Field Goal Attempted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FTr",
		},
		"stat:ftr": {
			desc: "Free Throw Attempt Rate (FTA / FGA)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FT/FGA",
		},
		"stat:gmsc": {
			desc: "Game Score",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GmSc",
		},
		"stat:nrtg": {
			desc: "Net Rating (point differential per 100 possessions)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "NRtg",
		},
		"stat:orb": {
			desc: "Offensive Rebounds",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ORB",
		},
		"stat:orbp": {
			desc: "Percentage of available offensive rebounds grabbed",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ORB%",
		},
		"stat:ortg": {
			desc: "Offensive Rating (points produced/scored per 100 possessions)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ORtg",
		},
		"stat:ows": {
			desc: "Offensive Win Shares",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OWS",
		},
		"stat:pace": {
			desc: "Possessions Per Game",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pace",
		},
		"stat:per": {
			desc: "Player Efficiency Rating",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PER",
		},
		"stat:pf": {
			desc: "Personal Fouls",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PF",
		},
		"stat:pl": {
			desc: "Pythagorean Losses (expected losses based on points scored and allowed)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PL",
		},
		"stat:pts": {
			desc: "Points",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PTS",
		},
		"stat:pw": {
			desc: "Pythagorean Wins (expected wins based on points scored and allowed)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PW",
		},
		"stat:stl": {
			desc: "Steals",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "STL",
		},
		"stat:stlp": {
			desc: "Percentage of opponent possessions ending in steals",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "STL%",
		},
		"stat:tovp": {
			desc: "Turnovers per 100 plays",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TOV%",
		},
		"stat:trb": {
			desc: "Total Rebounds",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TRB",
		},
		"stat:trbp": {
			desc: "Percentage of available rebounds grabbed",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TRB%",
		},
		"stat:tsp": {
			desc: "True Shooting Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TS%",
		},
		"stat:tov": {
			desc: "Turnovers",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TOV",
		},
		"stat:usgp": {
			desc: "Percentage of team plays used",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "USG%",
		},
		"stat:ws": {
			desc: "Win Shares",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "WS",
		},
		"stat:wsPerPlayer": {
			desc: "Win Shares Per Player",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "WS/Player",
		},
		"stat:ws48": {
			desc: "Win Shares Per 48 Minutes",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "WS/48",
		},
		"stat:obpm": {
			desc: "Offensive Box Plus-Minus",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OBPM",
		},
		"stat:dbpm": {
			desc: "Defensive Box Plus-Minus",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "DBPM",
		},
		"stat:bpm": {
			desc: "Box Plus-Minus",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BPM",
		},
		"stat:vorp": {
			desc: "Value Over Replacement Player",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "VORP",
		},
		"stat:fgAtRim": {
			desc: "At Rim Made",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "M",
		},
		"stat:fgaAtRim": {
			desc: "At Rim Attempted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "A",
		},
		"stat:fgpAtRim": {
			desc: "At Rim Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "%",
		},
		"stat:fgLowPost": {
			desc: "Low Post Made",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "M",
		},
		"stat:fgaLowPost": {
			desc: "Low Post Attempted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "A",
		},
		"stat:fgpLowPost": {
			desc: "Low Post Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "%",
		},
		"stat:fgMidRange": {
			desc: "Mid Range Made",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "M",
		},
		"stat:fgaMidRange": {
			desc: "Mid Range Attempted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "A",
		},
		"stat:fgpMidRange": {
			desc: "Mid Range Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "%",
		},
		"stat:dd": {
			desc: "Double Doubles",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "DD",
		},
		"stat:td": {
			desc: "Triple Doubles",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TD",
		},
		"stat:qd": {
			desc: "Quadruple Doubles",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "QD",
		},
		"stat:fxf": {
			desc: "Five by Fives",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "5x5",
		},
	},
	football: {
		"pos:QB": {
			desc: "Quarterback",
			sortType: "number",
			title: "QB",
		},
		"pos:RB": {
			desc: "Running Back",
			sortType: "number",
			title: "RB",
		},
		"pos:WR": {
			desc: "Wide Receiver",
			sortType: "number",
			title: "WR",
		},
		"pos:TE": {
			desc: "Tight End",
			sortType: "number",
			title: "TE",
		},
		"pos:OL": {
			desc: "Offensive Lineman",
			sortType: "number",
			title: "OL",
		},
		"pos:DL": {
			desc: "Defensive Lineman",
			sortType: "number",
			title: "DL",
		},
		"pos:LB": {
			desc: "Linebacker",
			sortType: "number",
			title: "LB",
		},
		"pos:CB": {
			desc: "Cornerback",
			sortType: "number",
			title: "CB",
		},
		"pos:S": {
			desc: "Safety",
			sortType: "number",
			title: "S",
		},
		"pos:K": {
			desc: "Kicker",
			sortType: "number",
			title: "K",
		},
		"pos:P": {
			desc: "Punter",
			sortType: "number",
			title: "P",
		},
		"rating:thv": {
			desc: "Throwing Vision",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ThV",
		},
		"rating:thp": {
			desc: "Throwing Power",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ThP",
		},
		"rating:tha": {
			desc: "Throwing Accuracy",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ThA",
		},
		"rating:bsc": {
			desc: "Ball Security",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BSc",
		},
		"rating:elu": {
			desc: "Elusiveness",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Elu",
		},
		"rating:rtr": {
			desc: "Route Running",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "RtR",
		},
		"rating:hnd": {
			desc: "Hands",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Hnd",
		},
		"rating:rbk": {
			desc: "Run Blocking",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "RBk",
		},
		"rating:pbk": {
			desc: "Pass Blocking",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PBk",
		},
		"rating:pcv": {
			desc: "Pass Coverage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PCv",
		},
		"rating:tck": {
			desc: "Tackling",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Tck",
		},
		"rating:prs": {
			desc: "Pass Rushing",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PRs",
		},
		"rating:rns": {
			desc: "Run Stopping",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "RnS",
		},
		"rating:kpw": {
			desc: "Kicking Power",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "KPw",
		},
		"rating:kac": {
			desc: "Kicking Accuracy",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "KAc",
		},
		"rating:ppw": {
			desc: "Punting Power",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PPw",
		},
		"rating:pac": {
			desc: "Punting Accuracy",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PAc",
		},
		"rating:ovrQB": {
			desc: "Overall Rating (QB)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrQB",
		},
		"rating:ovrRB": {
			desc: "Overall Rating (RB)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrRB",
		},
		"rating:ovrWR": {
			desc: "Overall Rating (WR)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrWR",
		},
		"rating:ovrTE": {
			desc: "Overall Rating (TE)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrTE",
		},
		"rating:ovrOL": {
			desc: "Overall Rating (OL)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrOL",
		},
		"rating:ovrDL": {
			desc: "Overall Rating (DL)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrDL",
		},
		"rating:ovrLB": {
			desc: "Overall Rating (LB)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrLB",
		},
		"rating:ovrCB": {
			desc: "Overall Rating (CB)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrCB",
		},
		"rating:ovrS": {
			desc: "Overall Rating (S)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrS",
		},
		"rating:ovrK": {
			desc: "Overall Rating (K)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrK",
		},
		"rating:ovrP": {
			desc: "Overall Rating (P)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrP",
		},
		"rating:ovrKR": {
			desc: "Overall Rating (KR)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrKR",
		},
		"rating:ovrPR": {
			desc: "Overall Rating (PR)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrPR",
		},
		"rating:potQB": {
			desc: "Potential Rating (QB)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotQB",
		},
		"rating:potRB": {
			desc: "Potential Rating (RB)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotRB",
		},
		"rating:potWR": {
			desc: "Potential Rating (WR)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotWR",
		},
		"rating:potTE": {
			desc: "Potential Rating (TE)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotTE",
		},
		"rating:potOL": {
			desc: "Potential Rating (OL)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotOL",
		},
		"rating:potDL": {
			desc: "Potential Rating (DL)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotDL",
		},
		"rating:potLB": {
			desc: "Potential Rating (LB)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotLB",
		},
		"rating:potCB": {
			desc: "Potential Rating (CB)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotCB",
		},
		"rating:potS": {
			desc: "Potential Rating (S)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotS",
		},
		"rating:potK": {
			desc: "Potential Rating (K)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotK",
		},
		"rating:potP": {
			desc: "Potential Rating (P)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotP",
		},
		"rating:potKR": {
			desc: "Potential Rating (KR)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotKR",
		},
		"rating:potPR": {
			desc: "Potential Rating (PR)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotPR",
		},
		"stat:fmb": {
			desc: "Fumbles",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Fmb",
		},
		"stat:fmbLost": {
			desc: "Fumbles Lost",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FL",
		},
		"stat:fp": {
			desc: "Fantasy Points",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FP",
		},
		"stat:pssCmp": {
			desc: "Completions",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Cmp",
		},
		"stat:pss": {
			desc: "Passing Attempts",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Att",
		},
		"stat:pssYds": {
			desc: "Passing Yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Yds",
		},
		"stat:pssTD": {
			desc: "Passing Touchdowns",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TD",
		},
		"stat:pssInt": {
			desc: "Interceptions",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Int",
		},
		"stat:pssLng": {
			desc: "Longest Pass",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Lng",
		},
		"stat:pssSk": {
			desc: "Times Sacked",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Sk",
		},
		"stat:pssSkYds": {
			desc: "Yards lost due to sacks",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Yds",
		},
		"stat:rus": {
			desc: "Rushing Attempts",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Rush",
		},
		"stat:rusYds": {
			desc: "Rushing Yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Yds",
		},
		"stat:rusTD": {
			desc: "Rushing Touchdowns",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TD",
		},
		"stat:rusLng": {
			desc: "Longest Run",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Lng",
		},
		"stat:tgt": {
			desc: "Targets",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Tgt",
		},
		"stat:rec": {
			desc: "Receptions",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Rec",
		},
		"stat:recYds": {
			desc: "Receiving Yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Yds",
		},
		"stat:recTD": {
			desc: "Receiving Touchdowns",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TD",
		},
		"stat:recLng": {
			desc: "Longest Reception",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Lng",
		},
		"stat:pr": {
			desc: "Punt Returns",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PR",
		},
		"stat:prYds": {
			desc: "Punt Return Yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Yds",
		},
		"stat:prTD": {
			desc: "Punts returned for touchdowns",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TD",
		},
		"stat:prLng": {
			desc: "Longest Punt Return",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Lng",
		},
		"stat:kr": {
			desc: "Kickoff Returns",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "KR",
		},
		"stat:krYds": {
			desc: "Kickoff Return Yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Yds",
		},
		"stat:krTD": {
			desc: "Kickoffs returned for touchdowns",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TD",
		},
		"stat:krLng": {
			desc: "Longest Kickoff Return",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Lng",
		},
		"stat:defInt": {
			desc: "Interceptions",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Int",
		},
		"stat:defIntYds": {
			desc: "Yards interceptions were returned for",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Yds",
		},
		"stat:defIntTD": {
			desc: "Interceptions returned for touchdowns",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TD",
		},
		"stat:defIntLng": {
			desc: "Longest Interception Return",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Lng",
		},
		"stat:defPssDef": {
			desc: "Passes Defended",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PD",
		},
		"stat:defFmbFrc": {
			desc: "Forced Fumbles",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FF",
		},
		"stat:defFmbRec": {
			desc: "Fumbles Recovered",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FR",
		},
		"stat:defFmbYds": {
			desc: "Yards fumbles were returned for",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Yds",
		},
		"stat:defFmbTD": {
			desc: "Fumbles returned for touchdowns",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TD",
		},
		"stat:defFmbLng": {
			desc: "Longest Fumble Return",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Lng",
		},
		"stat:defSk": {
			desc: "Sacks",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Sk",
		},
		"stat:defTckSolo": {
			desc: "Solo Tackles",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Solo",
		},
		"stat:defTckAst": {
			desc: "Assists On Tackles",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Ast",
		},
		"stat:defTckLoss": {
			desc: "Tackles For Loss",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TFL",
		},
		"stat:defSft": {
			desc: "Safeties Scored",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Sfty",
		},
		"stat:fg0": {
			desc: "Field Goals Made, 19 yards and under",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FG10",
		},
		"stat:fga0": {
			desc: "Field Goals Attempted, 19 yards and under",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FGA10",
		},
		"stat:fg20": {
			desc: "Field Goals Made, 20-29 yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FG20",
		},
		"stat:fga20": {
			desc: "Field Goals Attempted, 20-29 yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FGA20",
		},
		"stat:fg30": {
			desc: "Field Goals Made, 30-39 yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FG30",
		},
		"stat:fga30": {
			desc: "Field Goals Attempted, 30-39 yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FGA30",
		},
		"stat:fg40": {
			desc: "Field Goals Made, 40-49 yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FG40",
		},
		"stat:fga40": {
			desc: "Field Goals Attempted, 40-49 yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FGA40",
		},
		"stat:fg50": {
			desc: "Field Goals Made, 50+ yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FG50",
		},
		"stat:fga50": {
			desc: "Field Goals Attempted, 50+ yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FGA50",
		},
		"stat:fgLng": {
			desc: "Longest Field Goal",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Lng",
		},
		"stat:xp": {
			desc: "Extra Points Made",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "XPM",
		},
		"stat:xpa": {
			desc: "Extra Points Attempted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "XPA",
		},
		"stat:pnt": {
			desc: "Times Punted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pnt",
		},
		"stat:pntYds": {
			desc: "Total Punt Yardage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Yds",
		},
		"stat:pntLng": {
			desc: "Longest Punt",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Lng",
		},
		"stat:pntBlk": {
			desc: "Times Punts Blocked",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Blk",
		},
		"stat:pen": {
			desc: "Penalties",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pen",
		},
		"stat:penYds": {
			desc: "Penalty Yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Yds",
		},
		"stat:cmpPct": {
			desc: "Completion Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pct",
		},
		"stat:qbRat": {
			desc: "Quarterback Rating",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "QBRat",
		},
		"stat:rusYdsPerAtt": {
			desc: "Rushing Yards Per Attempt",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/A",
		},
		"stat:fg": {
			desc: "Field Goals Made",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FGM",
		},
		"stat:fga": {
			desc: "Field Goals Attempted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FGA",
		},
		"stat:fgPct": {
			desc: "Field Goal Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pct",
		},
		"stat:xpPct": {
			desc: "Extra Point Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pct",
		},
		"stat:kickingPts": {
			desc: "Kicking Points",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pts",
		},
		"stat:pntYdsPerAtt": {
			desc: "Yards Per Punt",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/A",
		},
		"stat:pntTB": {
			desc: "Punt Touchbacks",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TB",
		},
		"stat:pntIn20": {
			desc: "Punts Inside 20",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "In20",
		},
		"stat:krYdsPerAtt": {
			desc: "Yards Per Kick Return",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/A",
		},
		"stat:prYdsPerAtt": {
			desc: "Yards Per Punt Return",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/A",
		},
		"stat:defTck": {
			desc: "Total Tackles",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Tck",
		},
		"stat:keyStats": {
			desc: "Key Stats",
			sortSequence: ["desc", "asc"],
			sortType: "string",
			title: "Stats",
		},
		"stat:pts": {
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pts",
		},
		"stat:yds": {
			desc: "Offensive Yards",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Yds",
		},
		"stat:ply": {
			desc: "Plays",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Ply",
		},
		"stat:ydsPerPlay": {
			desc: "Yards Per Play",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/P",
		},
		"stat:tov": {
			desc: "Turnovers",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TO",
		},
		"stat:drives": {
			desc: "Number of Drives",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "#Dr",
		},
		"stat:drivesScoringPct": {
			desc: "Percentage of drives ending in a score",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Sc%",
		},
		"stat:drivesTurnoverPct": {
			desc: "Percentage of drives ending in a turnover",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TO%",
		},
		"stat:avgFieldPosition": {
			desc: "Average Starting Field Position",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Start",
		},
		"stat:timePerDrive": {
			desc: "Time Per Drive (minutes)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Tm/D",
		},
		"stat:playsPerDrive": {
			desc: "Number of Plays Per Drive",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Ply/D",
		},
		"stat:ydsPerDrive": {
			desc: "Yards Per Drive",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/D",
		},
		"stat:ptsPerDrive": {
			desc: "Points Per Drive",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pts/D",
		},
		"stat:qbRec": {
			desc: "Record as primary QB",
			sortSequence: ["desc", "asc"],
			sortType: "record",
			title: "QBRec",
		},
		"stat:qbW": {
			desc: "Wins as primary QB",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "QBW",
		},
		"stat:qbL": {
			desc: "Losses as primary QB",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "QBL",
		},
		"stat:qbT": {
			desc: "Ties as primary QB",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "QBT",
		},
		"stat:qbOTL": {
			desc: "Overtime losses as primary QB",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "QBOTL",
		},
		"stat:pssTDPct": {
			desc: "Percentage of passes that result in touchdowns",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TD%",
		},
		"stat:pssIntPct": {
			desc: "Percentage of passes that result in interceptions",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Int%",
		},
		"stat:pssYdsPerAtt": {
			desc: "Pass Yards Per Attempt",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/A",
		},
		"stat:pssAdjYdsPerAtt": {
			desc: "Adjusted Pass Yards Per Attempt ((yds + 20 * TD - 45 * int) / att)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "AY/A",
		},
		"stat:pssYdsPerCmp": {
			desc: "Pass Yards Per Completion",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/C",
		},
		"stat:pssYdsPerGame": {
			desc: "Pass Yards Per Game",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/G",
		},
		"stat:pssNetYdsPerAtt": {
			desc: "Net Pass Yards Per Attempt (passes and sacks)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "NY/A",
		},
		"stat:pssAdjNetYdsPerAtt": {
			desc: "Adjusted Net Pass Yards Per Attempt ((yds + 20 * TD - 45 * int - skYds) / (att + sk))",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ANY/A",
		},
		"stat:pssSkPct": {
			desc: "Percentage of times sacked when attempting a pass",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Sk%",
		},
		"stat:rusYdsPerGame": {
			desc: "Rushing Yards Per Game",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/G",
		},
		"stat:rusPerGame": {
			desc: "Rushing Attempts Per Game",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "A/G",
		},
		"stat:recYdsPerRec": {
			desc: "Yards Per Reception",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/R",
		},
		"stat:recPerGame": {
			desc: "Receptions Per Game",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "R/G",
		},
		"stat:recYdsPerGame": {
			desc: "Receiving Yards Per Game",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/G",
		},
		"stat:recCatchPct": {
			desc: "Catch Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Ctch%",
		},
		"stat:touches": {
			desc: "Touches (Rushing Attempts And Receptions)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Tch",
		},
		"stat:ydsPerTouch": {
			desc: "Yards Per Touch",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Y/Tch",
		},
		"stat:ydsFromScrimmage": {
			desc: "Total Rushing and Receiving Yards From Scrimmage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "YScm",
		},
		"stat:rusRecTD": {
			desc: "Total Rushing and Receiving Touchdowns",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "RRTD",
		},
		"stat:allPurposeYds": {
			desc: "All Purpose Yards (Rushing, Receiving, and Kick/Punt/Fumble/Interception Returns)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "APY",
		},
		"stat:av": {
			desc: "Approximate Value",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "AV",
		},
		"stat:avPerPlayer": {
			desc: "Approximate Value Per Player",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "AV/Player",
		},
	},
	hockey: {
		"pos:C": {
			desc: "Center",
			sortType: "number",
			title: "C",
		},
		"pos:W": {
			desc: "Wing",
			sortType: "number",
			title: "W",
		},
		"pos:D": {
			desc: "Defenseman",
			sortType: "number",
			title: "D",
		},
		"pos:G": {
			desc: "Goalie",
			sortType: "number",
			title: "G",
		},
		"rating:pss": {
			desc: "Passing",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Pss",
		},
		"rating:wst": {
			desc: "Wristshot",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Wst",
		},
		"rating:sst": {
			desc: "Slapshot",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Sst",
		},
		"rating:stk": {
			desc: "Stickhandling",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Stk",
		},
		"rating:oiq": {
			desc: "Offensive IQ",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "oIQ",
		},
		"rating:chk": {
			desc: "Checking",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Chk",
		},
		"rating:blk": {
			desc: "Shot Blocking",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Blk",
		},
		"rating:fcf": {
			desc: "Faceoffs",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Fcf",
		},
		"rating:diq": {
			desc: "Defensive IQ",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "dIQ",
		},
		"rating:glk": {
			desc: "Goalkeeping",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "Glk",
		},
		"rating:ovrC": {
			desc: "Overall Rating (Center)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrC",
		},
		"rating:ovrW": {
			desc: "Overall Rating (Winger)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrW",
		},
		"rating:ovrD": {
			desc: "Overall Rating (Defenseman)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrD",
		},
		"rating:ovrG": {
			desc: "Overall Rating (Goalie)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OvrG",
		},
		"rating:potC": {
			desc: "Potential Rating (Center)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotC",
		},
		"rating:potW": {
			desc: "Potential Rating (Winger)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotW",
		},
		"rating:potD": {
			desc: "Potential Rating (Defenseman)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotD",
		},
		"rating:potG": {
			desc: "Potential Rating (Goalie)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PotG",
		},
		"stat:gpGoalie": {
			desc: "Games Played (Goalie)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: gp,
		},
		"stat:gpSkater": {
			desc: "Games Played (Skater)",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: gp,
		},
		"stat:pm": {
			desc: "Plus/Minus",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "+/-",
		},
		"stat:shft": {
			desc: "Shifts",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SHFT",
		},
		"stat:pim": {
			desc: "Penalty Minutes",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PIM",
		},
		"stat:evG": {
			desc: "Even Strength Goals",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "evG",
		},
		"stat:ppG": {
			desc: "Power Play Goals",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ppG",
		},
		"stat:shG": {
			desc: "Short-Handed Goals",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "shG",
		},
		"stat:gwG": {
			desc: "Game Winning Goals",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "gwG",
		},
		"stat:evA": {
			desc: "Even Strength Assists",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "evA",
		},
		"stat:ppA": {
			desc: "Power Play Assists",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ppA",
		},
		"stat:shA": {
			desc: "Short-Handed Assists",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "shA",
		},
		"stat:gwA": {
			desc: "Game Winning Assists",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "gwA",
		},
		"stat:s": {
			desc: "Shots on Goal",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "S",
		},
		"stat:tsa": {
			desc: "Total Shots Attempted",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TSA",
		},
		"stat:fow": {
			desc: "Faceoff Wins",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FOW",
		},
		"stat:fol": {
			desc: "Faceoff Losses",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FOL",
		},
		"stat:foPct": {
			desc: "Faceoff Win Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "FO%",
		},
		"stat:blk": {
			desc: "Blocks",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "BLK",
		},
		"stat:hit": {
			desc: "Hits",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "HIT",
		},
		"stat:tk": {
			desc: "Takeaways",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "TK",
		},
		"stat:gv": {
			desc: "Giveaways",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GV",
		},
		"stat:ga": {
			desc: "Goals Against",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GA",
		},
		"stat:sv": {
			desc: "Saves",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SV",
		},
		"stat:so": {
			desc: "Shutouts",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SO",
		},
		"stat:g": {
			desc: "Goals",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "G",
		},
		"stat:a": {
			desc: "Assists",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "A",
		},
		"stat:pts": {
			desc: "Points",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PTS",
		},
		"stat:sPct": {
			desc: "Shooting Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "S%",
		},
		"stat:svPct": {
			desc: "Save Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SV%",
		},
		"stat:sa": {
			desc: "Shots Against",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "SA",
		},
		"stat:gaa": {
			desc: "Goals Against Average",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GAA",
		},
		"stat:keyStats": {
			desc: "Key Stats",
			sortSequence: ["desc", "asc"],
			sortType: "string",
			title: "Stats",
		},
		"stat:ps": {
			desc: "Point Shares",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PS",
		},
		"stat:psPerPlayer": {
			desc: "Point Shares Per Player",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PS/Player",
		},
		"stat:ops": {
			desc: "Offensive Point Shares",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "OPS",
		},
		"stat:dps": {
			desc: "Defensive Point Shares",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "DPS",
		},
		"stat:gps": {
			desc: "Goalie Point Shares",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GPS",
		},
		"stat:gc": {
			desc: "Goals Created",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GC",
		},
		"stat:amin": {
			desc: "Average Time On Ice",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ATOI",
		},
		"stat:ppMin": {
			desc: "Power Play Time On Ice",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "ppTOI",
		},
		"stat:shMin": {
			desc: "Short Handed Time On Ice",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "shTOI",
		},
		"stat:ppo": {
			desc: "Power Play Opportunities",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PPO",
		},
		"stat:ppPct": {
			desc: "Power Play Percentage",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "PP%",
		},
		"stat:gRec": {
			desc: "Record as primary G",
			sortSequence: ["desc", "asc"],
			sortType: "record",
			title: "Rec",
		},
		"stat:gW": {
			desc: "Wins as primary G",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GW",
		},
		"stat:gL": {
			desc: "Losses as primary G",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GL",
		},
		"stat:gT": {
			desc: "Ties as primary G",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GT",
		},
		"stat:gOTL": {
			desc: "Overtime losses as primary G",
			sortSequence: ["desc", "asc"],
			sortType: "number",
			title: "GOTL",
		},
	},
});
const cols: {
	[key: string]: ColTemp;
} = {
	"": {
		sortSequence: ["desc", "asc"],
	},
	"#": {},
	"@": {
		desc: "Home or Away",
	},
	"%": {
		desc: "Percentage",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Active: {
		desc: "Number of Players Still Active",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	HoF: {
		desc: "Number of Players in the Hall of Fame",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"# Brothers": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"# Daughters": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"# Fathers": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"# Mothers": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"# Players": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"# Seasons": {
		desc: "Number of Seasons",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"# Sisters": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"# Sons": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"# Teams": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"# Trades": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	A: {
		desc: "Attempted",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Acquired: {
		desc: "How Player Was Acquired",
	},
	Actions: {},
	Age: {
		sortType: "number",
	},
	Amount: {
		sortSequence: ["desc", "asc"],
		sortType: "currency",
	},
	"Asking For": {
		sortSequence: ["desc", "asc"],
		sortType: "currency",
	},
	"Avg Attendance": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	AvgAge: {
		desc: "Average age, weighted by minutes played",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "Age",
	},
	Born: {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Cap: {
		desc: "Salary Cap Issue",
		sortSequence: ["asc", "desc"],
		sortType: "number",
		width: "1px",
	},
	"Cap Space": {
		sortSequence: ["desc", "asc"],
		sortType: "currency",
	},
	"Captain 1": {},
	"Captain 2": {},
	Cash: {
		sortSequence: ["desc", "asc"],
		sortType: "currency",
	},
	Change: {
		desc: "Difference between pre-lottery rank and draft lottery result",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Coaching: {
		desc: "Coaching Level",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	College: {},
	Compare: {
		sortSequence: [],
	},
	Conference: {},
	Contract: {
		sortSequence: ["desc", "asc"],
		sortType: "currency",
	},
	Count: {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Country: {
		sortType: "country",
	},
	Created: {
		desc: "Created Date",
		searchType: "string",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Current: {
		desc: "Current Team Rating (With Injuries)",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Current Contract": {
		desc: "Current Contract",
		sortSequence: ["desc", "asc"],
		sortType: "currency",
		title: "Current",
	},
	"Projected Contract": {
		desc: "Projected Contract",
		sortSequence: ["desc", "asc"],
		sortType: "currency",
		title: "Projected",
	},
	Details: {},
	Died: {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Diff: {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Difficulty: {
		sortSequence: ["desc", "asc"],
	},
	Division: {},
	Draft: {
		noSearch: true,
		sortSequence: [],
	},
	"Draft Pick": {
		sortType: "number",
	},
	"Draft Picks": {
		sortSequence: [],
	},
	"Draft Round": {
		sortType: "number",
	},
	"Draft Year": {
		sortType: "number",
	},
	Drafted: {
		sortType: "number",
	},
	"Dunk Winner": {
		desc: "Slam Dunk Contest Winner",
	},
	End: {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Exp: {
		desc: "Contract Expiration",
		sortType: "number",
	},
	Experience: {
		desc: "Number of Years in the League",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Facilities: {
		desc: "Facilities Level",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Finals: {
		desc: "Finals Appearances",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Finals Won": {
		desc: "Finals Won",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Finals Lost": {
		desc: "Finals Lost",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	From: {},
	GB: {
		desc: "Games Back",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Games: {
		desc: "Number of Games",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	GOAT: {
		desc: "GOAT Score",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Healthy: {
		desc: "Team Rating (When Healthy)",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Height: {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	HOF: {
		sortSequence: ["desc", "asc"],
	},
	Injury: {},
	L: {
		desc: "Losses",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	L10: {
		desc: "Last Ten Games",
		sortSequence: ["desc", "asc"],
		sortType: "lastTen",
	},
	Last: {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Last Played": {
		desc: "Last Played Date",
		sortSequence: ["desc", "asc"],
		searchType: "string",
		sortType: "number",
	},
	"Last Season": {
		desc: "Last Season with Team",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"League Champion": {},
	League: {
		desc: "League Name",
	},
	Links: {
		noSearch: true,
		sortSequence: [],
	},
	M: {
		desc: "Made",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Mood: {
		width: "1px",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Name: {},
	Normal: {
		desc: "Normal Difficulty",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Hard: {
		desc: "Hard Difficulty",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Health: {
		desc: "Health Level",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Insane: {
		desc: "Insane Difficulty",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Negotiate: {
		sortSequence: [],
		width: "1px",
	},
	Note: {},
	Odds: {
		desc: "Pre-lottery odds of getting this pick",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Opp: {
		desc: "Opponent",
	},
	Ovr: {
		desc: "Overall Rating",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Ovr Drop": {
		desc: "Decrease in Overall Rating",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	PA: {
		desc: `${isSport("hockey") ? "Goals" : "Points"} Against`,
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: isSport("hockey") ? "GA" : undefined,
	},
	PS: {
		desc: `${isSport("hockey") ? "Goals" : "Points"} Scored`,
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: isSport("hockey") ? "GF" : undefined,
	},
	"PA/g": {
		desc: `${isSport("hockey") ? "Goals" : "Points"} Against Per Game`,
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: isSport("hockey") ? "GA" : undefined,
	},
	"PS/g": {
		desc: `${isSport("hockey") ? "Goals" : "Points"} Scored Per Game`,
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: isSport("hockey") ? "GF" : undefined,
	},
	Payroll: {
		sortSequence: ["desc", "asc"],
		sortType: "currency",
	},
	"Peak Ovr": {
		desc: "Peak Overall Rating",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Phase: {
		desc: "League Season and Phase",
		sortSequence: ["desc", "asc"],
	},
	Pick: {
		desc: "Draft Pick",
		sortType: "draftPick",
	},
	Players: {},
	Pop: {
		desc: "Region Population",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Playoffs: {
		desc: "Playoff Appearances",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Pos: {
		desc: "Position",
	},
	Pot: {
		desc: "Potential Rating",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Pot Drop": {
		desc: "Decrease in Potential Rating",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Pre-Lottery": {
		desc: "Pre-lottery rank",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Prog: {
		desc: "Progression From Previous Season",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Profit: {
		sortSequence: ["desc", "asc"],
		sortType: "currency",
	},
	PTS: {
		desc: "Points",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"PTS%": {
		desc: "Points Divided By Maximum Points",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Received: {
		desc: "Assets Received in Trade",
	},
	Record: {
		desc: "Record",
		sortType: "record",
	},
	Relation: {},
	Result: {},
	Retired: {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Revenue: {
		sortSequence: ["desc", "asc"],
		sortType: "currency",
	},
	"Rookie Ovr": {
		desc: "Rookie Overall Rating",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Roster Spots": {
		desc: "Number of Open Roster Spots",
		sortSequence: ["desc", "asc"],
	},
	"Rounds Lost": {
		desc: "Playoff Rounds Lost",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Rounds Won": {
		desc: "Playoff Rounds Won",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Runner Up": {},
	Salary: {
		sortSequence: ["desc", "asc"],
		sortType: "currency",
	},
	Scouting: {
		desc: "Scouting Level",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Season: {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Seed: {
		desc: "Playoff Seed",
		sortType: "number",
	},
	Skills: {},
	Start: {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Strategy: {},
	T: {
		desc: "Ties",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Ticket Price": {
		sortSequence: ["desc", "asc"],
		sortType: "currency",
	},
	Trade: {
		noSearch: true,
	},
	OTL: {
		desc: "Overtime Losses",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Team: {},
	"Team Rating": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Team Rating (With Injuries)": {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Three-Point Winner": {
		desc: "Three-Point Contest Winner",
	},
	Titles: {
		desc: "Championships Won",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Type: {
		desc: "Type of Game",
	},
	TypeInjury: {
		desc: "Type of Injury",
		title: "Type",
	},
	W: {
		desc: "Wins",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	Weight: {
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	"Win%": {
		desc: "Winning Percentage",
		sortSequence: ["desc", "asc"],
		sortType: "number",
	},
	X: {
		desc: "Exclude from counter offers",
		noSearch: true,
		sortSequence: [],
	},
	Year: {
		sortType: "number",
	},
	Summary: {},
	"rating:endu": {
		desc: "Endurance",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "End",
	},
	"rating:hgt": {
		desc: "Height",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "Hgt",
	},
	"rating:spd": {
		desc: "Speed",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "Spd",
	},
	"rating:stre": {
		desc: "Strength",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "Str",
	},
	"stat:gp": {
		desc: "Games Played",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: gp,
	},
	"stat:gpPerPlayer": {
		desc: "Games Played Per Player",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: `${gp}/Player`,
	},
	"stat:gs": {
		desc: "Games Started",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "GS",
	},
	"stat:jerseyNumber": {
		desc: "Jersey Number",
		sortSequence: ["asc", "desc"],
		sortType: "number",
		title: "#",
	},
	"stat:min": {
		desc: isSport("hockey") ? "Time On Ice" : "Minutes",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: isSport("hockey") ? "TOI" : "MP",
	},
	"stat:mov": {
		desc: "Average Margin of Victory",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "MOV",
	},
	"stat:diff": {
		desc: "Point Differential",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "Diff",
	},
	"stat:yearsWithTeam": {
		desc: "Years With Team",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "YWT",
	},
	"count:allDefense": {
		desc: "All-Defensive Team",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "ADT",
	},
	"count:allLeague": {
		desc: "All-League Team",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "ALT",
	},
	"count:allRookie": {
		desc: "All-Rookie Team",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "ART",
	},
	"count:allStar": {
		desc: "All-Star",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "AS",
	},
	"count:allOffense": {
		desc: "All-Offensive Team",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "AOT",
	},
	"count:allStarMVP": {
		desc: "All-Star MVP",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "ASMVP",
	},
	"count:bestRecord": {
		desc: "Best Record",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "BR",
	},
	"count:bestRecordConf": {
		desc: "Best Conference Record",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "BRC",
	},
	"count:dpoy": {
		desc: "Defensive Player of the Year",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "DPOY",
	},
	"count:dfoy": {
		desc: "Defensive Forward of the Year",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "DFOY",
	},
	"count:goy": {
		desc: "Goalie of the Year",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "GOY",
	},
	"count:mip": {
		desc: "Most Improved Player",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "MIP",
	},
	"count:mvp": {
		desc: "Most Valuable Player",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "MVP",
	},
	"count:roy": {
		desc: "Rookie of the Year",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "ROY",
	},
	"count:smoy": {
		desc: "Sixth Man of the Year",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "SMOY",
	},
	"count:oroy": {
		desc: "Offensive Rookie of the Year",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "OROY",
	},
	"count:droy": {
		desc: "Defensive Rookie of the Year",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "DROY",
	},
	"count:poy": {
		desc: "Pitcher of the Year",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "POY",
	},
	"count:rpoy": {
		desc: "Relief Pitcher of the Year",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "RPOY",
	},
	"award:dpoy": {
		desc: "Defensive Player of the Year",
		title: "DPOY",
	},
	"award:dfoy": {
		desc: "Defensive Forward of the Year",
		title: "DFOY",
	},
	"award:goy": {
		desc: "Goalie of the Year",
		title: "GOY",
	},
	"award:finalsMvp": {
		desc: `${isSport("hockey") ? "Playoffs" : "Finals"} Most Valuable Player`,
		title: `${isSport("hockey") ? "Playoffs" : "Finals"} MVP`,
	},
	"award:mip": {
		desc: "Most Improved Player",
		title: "MIP",
	},
	"award:mvp": {
		desc: "Most Valuable Player",
		title: "MVP",
	},
	"award:roy": {
		desc: "Rookie of the Year",
		title: "ROY",
	},
	"award:smoy": {
		desc: "Sixth Man of the Year",
		title: "SMOY",
	},
	"award:poy": {
		desc: "Pitcher of the Year",
		title: "POY",
	},
	"award:rpoy": {
		desc: "Relief Pitcher of the Year",
		title: "RPOY",
	},
	"award:oroy": {
		desc: "Offensive Rookie of the Year",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "OROY",
	},
	"award:droy": {
		desc: "Defensive Rookie of the Year",
		sortSequence: ["desc", "asc"],
		sortType: "number",
		title: "DROY",
	},
	...sportSpecificCols,
};

const getCols = (
	titles: string[],
	overrides: Record<string, Partial<Col>> = {},
): Col[] => {
	return titles.map(title => {
		if (!Object.hasOwn(cols, title)) {
			throw new Error(`Unknown column: "${title}"`);
		}

		return {
			...cols[title],
			title: cols[title].title ?? title,
			...overrides[title],
		};
	});
};

export default getCols;
